import React from 'react'

function FeaturesList({ img, alt, title, onClickHandler }) {
  return (
    <div className='features__list' onClick={() => onClickHandler()}>
      <img src={img} alt={alt} />
      <span>{title}</span>
    </div>
  )
}

export default FeaturesList
