const list = [
  {
    img: '/images/eco.jpg',
    icon: '/icons/eco.svg',
    title: 'ЭКОЛОГИЧНЫЕ',
    text: 'В деревянном доме уникальный микроклимат, благотворно влияющие на здоровье человека. Круглый год оптимальный уровень влажности: от 45 до 60%. Дерево  выделяет фитонциды, которые обладают антисептическими, антиаллергенными и бактерицидными свойствами.',
  },
  {
    img: '/images/hot.jpg',
    icon: '/icons/hot.svg',
    title: 'ТЕПЛЫЕ',
    text: 'Древесина обладает превосходной теплоизоляцией – зимой она долго держит тепло, а летом сохраняет внутри приятную прохладу. Это уменьшает затраты на отопление и кондиционирование.',
  },
  {
    img: '/images/wood.jpg',
    icon: '/icons/wood.svg',
    title: 'НАДЕЖНЫЕ И ДОЛГОВЕЧНЫЕ',
    text: 'Самая крепкая часть дерева – это верхний слой, спрятанный под корой. Именно его срезают, делая оцилиндрованное бревно и брус. Мы снимаем с деревьев только кору.',
  },
  {
    img: '/images/wood-cabin.jpg',
    icon: '/icons/wood-cabin.svg',
    title: 'КРАСИВЫЕ И СТАТУСНЫЕ',
    text: 'Природа – гениальный художник. Каждый рубленый дом – неповторим, как и деревья, из которых он построен. Вы сэкономите на отделочных материалах – как внутри, так и снаружи, они просто',
  },
  {
    img: '/images/makeHome.jpg',
    icon: '/icons/makeHome.svg',
    title: 'БЫСТРО СОБИРАТЬ, ЛЕГКО ПЕРЕВОЗИТЬ',
    text: 'Древесина – самый удобный и лёгкий в сборке строительный материал. А технологии каркасно-балочного строительства напоминают конструктор. Дом легко можно перевезти в любую точку мира.',
  },
  {
    img: '/images/calendar.jpg',
    icon: '/icons/calendar.svg',
    title: 'МОЖНО СТРОИТЬ КРУГЫЙ ГОД',
    text: 'Процесс строительства не зависит от температурного режима. А в заготовленной зимой древесине не развиваются паразиты и грибки.',
  },
]

export default list
