import React, { useState } from 'react'
import { useForm, ValidationError } from '@formspree/react'
import './Feedback.scss'

function Feedback() {
  const [state, handleSubmit] = useForm('xpznwzjw')

  const [value, setValue] = useState('')
  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState()
  const [value3, setValue3] = useState('')

  const handleClear = (e) => {
    setValue('')
    setValue1('')
    setValue2('')
    setValue3('')
  }
  const handleChange = (e) => setValue(e.target.value)
  const handleChange1 = (e) => setValue1(e.target.value)
  const handleChange2 = (e) => setValue2(e.target.value)
  const handleChange3 = (e) => setValue3(e.target.value)

  /*  if (state.succeeded) {
    return <div>Thank you for signing up!</div>
  } */
  return (
    <section className='feedback'>
      <div className='container'>
        <div className='feedback__wrap'>
          <h2>ЗАПОЛНИТЕ ФОРМУ И Я ПЕРЕЗВОНЮ ВАМ В БЛИЖАЙШЕЕ ВРЕМЯ!</h2>

          <form
            className='feedback__form'
            onSubmit={(e) => {
              handleSubmit(e)
              handleClear()
            }}
          >
            <div className='feedback__item'>
              <input
                className='feedback__input'
                type='text'
                name='name'
                id='name'
                value={value}
                onChange={handleChange}
                placeholder='Ваше имя'
              />
            </div>
            <ValidationError prefix='Name' field='name' errors={state.errors} />
            <div className='feedback__item'>
              <input
                className='feedback__input'
                type='tel'
                name='tel'
                id='tel'
                value={value1}
                onChange={handleChange1}
                placeholder='+7 (_ _ _) _ _ _-_ _ - _ _  '
              />
            </div>
            <ValidationError prefix='Tel' field='tel' errors={state.errors} />
            <div className='feedback__item'>
              <input
                className='feedback__input'
                type='text'
                name='email'
                id='email'
                value={value2}
                onChange={handleChange2}
                placeholder='Эл.почта'
              />
              <ValidationError prefix='Email' field='email' errors={state.errors} />
            </div>
            <div className='feedback__item message-box'>
              <textarea
                className='feedback__input textArea'
                name='message'
                id='message'
                value={value3}
                onChange={handleChange3}
                placeholder='Сообщение'
              ></textarea>
              <ValidationError prefix='Message' field='message' errors={state.errors} />
            </div>
            <button
              type='submit'
              className='feedback__button'
              disabled={state.submitting}
              /*   onClick={() => handleClear()}  */
            >
              ОТПРАВИТЬ
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Feedback
