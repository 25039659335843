import React from 'react'
import { Navigation, EffectFade, Zoom } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import data from './workProcessPhoto'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/zoom'

import './WorksProcess.scss'

function WorksProcess() {
  return (
    <>
      <section className='worksProcess'>
        <div className='container'>
          <h2>РАБОЧИЙ ПРОЦЕСС</h2>

          <Swiper
            // install Swiper modules
            modules={[Navigation, EffectFade, Zoom]}
            /*  spaceBetween={15} */
            slidesPerView={3}
            navigation
            zoom
            breakpoints={{
              // when window width is >= 640px
              320: {
                width: 320,
                slidesPerView: 1,
              },
              480: {
                width: 480,
                slidesPerView: 1,
              },
              640: {
                width: 640,
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                width: 768,
                slidesPerView: 2,
              },
            }}
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <div className='swiper-zoom-container'>
                  <img className='swipers' src={item.img} alt={item.id} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default WorksProcess
