import './index.scss'

import Header from './components/header/Header'
import MainVideo from './components/main__video/MainVideo'
import AboutMe from './components/about-me/AboutMe'
import Features from './components/features/Features'
import FeaturesMain from './components/featuresCard/FeaturesMain'
import MyWorks from './components/myWorks/MyWorks'
import WorkProcess from './components/worksProcess/WorkProcess'
import Feedback from './components/feedBack/Feedback'
import Footer from './components/footer/Footer'

function App() {
  return (
    <div className='App'>
      <Header />
      <MainVideo />
      <AboutMe />
      <Features />
      <FeaturesMain />
      <MyWorks />
      <WorkProcess />
      <Feedback />
      <Footer />
    </div>
  )
}

export default App
