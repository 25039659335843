const data = [
  { id: 1, img: '/workProcess/1.jpg' },
  { id: 2, img: '/workProcess/2.jpg' },
  { id: 3, img: '/workProcess/3.jpg' },
  { id: 4, img: '/workProcess/4.jpg' },
  { id: 5, img: '/workProcess/5.jpg' },
  { id: 6, img: '/workProcess/6.jpg' },
  { id: 7, img: '/workProcess/7.jpg' },
  { id: 8, img: '/workProcess/8.jpg' },
  { id: 9, img: '/workProcess/9.jpg' },
  { id: 10, img: '/workProcess/10.jpg' },
]

export default data
