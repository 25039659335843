const house = [
  { id: 1, img: '/house/1.jpg' },
  { id: 2, img: '/house/2.jpg' },
  { id: 3, img: '/house/3.jpg' },
  { id: 4, img: '/house/4.jpg' },
  { id: 5, img: '/house/5.jpg' },
  { id: 6, img: '/house/6.jpg' },
  { id: 7, img: '/house/7.jpg' },
  { id: 8, img: '/house/8.jpg' },
  { id: 9, img: '/house/9.jpg' },
  { id: 10, img: '/house/10.jpg' },
  { id: 11, img: '/house/11.jpg' },
  { id: 12, img: '/house/12.jpg' },
]

export default house
