const summerhouse = [
  { id: 1, img: '/summerhouse/1.jpg' },
  { id: 2, img: '/summerhouse/2.jpg' },
  { id: 3, img: '/summerhouse/3.jpg' },
  { id: 4, img: '/summerhouse/4.jpg' },
  { id: 5, img: '/summerhouse/5.jpg' },
  { id: 6, img: '/summerhouse/6.jpg' },
  { id: 7, img: '/summerhouse/7.jpg' },
  { id: 8, img: '/summerhouse/8.jpg' },
  { id: 9, img: '/summerhouse/9.jpg' },
  { id: 10, img: '/summerhouse/10.jpg' },
  { id: 11, img: '/summerhouse/11.jpg' },
  { id: 12, img: '/summerhouse/12.jpg' },
]

export default summerhouse
