import React from 'react'
import FeaturesList from './FeaturesList'

import { nanoid } from 'nanoid'

function FeaturesLists({ list, onClickHandler }) {
  return (
    <>
      <div className='features__list-wrap'>
        {list.map((item, index) => (
          <FeaturesList
            key={nanoid()}
            img={item.icon}
            alt={item.title}
            title={item.title}
            onClickHandler={() => onClickHandler(index)}
          />
        ))}
      </div>
    </>
  )
}

export default FeaturesLists
