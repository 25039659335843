import React, { useState } from 'react'
import FeaturesLists from './FeaturesLists'
import FeaturesCard from './FeaturesCard'

import list from './features'

import './featuresCard.scss'

function FeaturesMain() {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section className='features-cards' id='features'>
      <div className='container'>
        <div className='main__wrap'>
          <FeaturesLists onClickHandler={(index) => setActiveIndex(index)} list={list} />
          <FeaturesCard activeFeature={list[activeIndex]} />
        </div>
      </div>
    </section>
  )
}

export default FeaturesMain
