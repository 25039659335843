import React, { useState } from 'react'
import ReactPlayer from 'react-player/youtube'

/* import Video from '../../videos/house.mp4' */

import './mainVideo.scss'
import playButton from '../../play.svg'

function MainVideo() {
  const [watchVideo, setWatchVideo] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)

  const onEnd = () => {
    setWatchVideo(false)
  }
  return (
    <>
      <section className='video'>
        <div className='container'>
          <div className='video__wrap'>
            <div className='video__info'>
              <h1>
                Как построить уникальный рубленый дом и не разориться? Неповторимый рубленый дом доступнее, чем вы
                думаете.
              </h1>
              <p>Главное – правильно выбрать мастера.</p>
              <button type='button' className='video__callMe'>
                <a href='tel:+79920200277'>Связаться с мастером</a>
              </button>
            </div>
            <button type='button' className='video__playBtn' onClick={() => setWatchVideo(!watchVideo)}>
              <img src={playButton} alt='проигрыватель' />
            </button>
            <div className={watchVideo ? 'visible' : 'hidden'}>
              <ReactPlayer
                url='https://www.youtube.com/watch?v=JsbqakWLNfQ&ab_channel=%D0%9A%D0%BE%D1%80%D0%B1%D0%B0%D0%9F%D0%B0%D0%B2%D0%B5%D0%BB'
                width='100%'
                height='100%'
                onEnded={onEnd}
                playing={isPlaying}
                controls={true}
              />
              <div
                className='closeIcon'
                onClick={() => {
                  setIsPlaying(!isPlaying)
                  setWatchVideo(!watchVideo)
                }}
              >
                <span className='close'></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MainVideo
