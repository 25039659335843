import React from 'react'
import './Footer.scss'
import logo from '../../logo.svg'
import insta from '../../footerIcon/insta.svg'
import mail from '../../footerIcon/email.svg'
import tel from '../../footerIcon/tel.svg'

function Footer() {
  return (
    <footer className='footer' id='footer'>
      <div className='container'>
        <div className='footer__wrap'>
          <div className='footer__wrap_description'>
            <img src={logo} alt='logo' />
            <p>
              Подробно расскажу о наших услугах, видах работ и типовых проектах, рассчитаю стоимость и подготовлю
              индивидуальное предложение!
            </p>
          </div>

          <div className='footer__wrap_info'>
            <ul className='footer__wrap_features'>
              <li>
                <a href='#aboutMe'>ОБО МНЕ</a>
              </li>
              <li>
                <a href='#myWorks'>МОИ РАБОТЫ</a>
              </li>
              <li>
                <a href='#features'>ПРЕИМУЩЕСТВА</a>
              </li>
            </ul>

            <ul className='footer__wrap_contacts'>
              <li>
                <a href='https://www.instagram.com/korba_woodwork/' target='_blank' rel='noopener noreferrer'>
                  <img src={insta} alt='/' />
                  <span>korba_woodwork</span>
                </a>
              </li>
              <li>
                <a href='mailto:istokles2017@yandex.ru' target='_blank' rel='noopener noreferrer'>
                  <img src={mail} alt='/' />
                  <span>istokles2017@yandex.ru</span>
                </a>
              </li>
              <li>
                <a href='tel:+79920200277'>
                  <img src={tel} alt='/' />
                  <span>+ 7 (992) 02-002-77</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*   <div className='footer__privacy'>
        <div className='footer__privicy_wrap'>
          <a href='/'> Политика конфиденциальности</a>
        </div>
      </div> */}
    </footer>
  )
}

export default Footer
