import React, { useState, useEffect } from 'react'

import BurgerActive from './BurgerActive'
import './header.scss'
import logo from '../../logo.svg'
import tel from '..//../tel.svg'

function Header() {
  const [burger, setBurger] = useState(false)

  useEffect(() => {
    burger && (document.body.style.overflow = 'hidden')
    !burger && (document.body.style.overflow = 'unset')
  }, [burger])

  return (
    <header className='header'>
      <div className='container'>
        <nav className='navbar'>
          <div className='navbar__menu'>
            <div className='navbar__logo'>
              <img src={logo} alt='logo' />
            </div>
            <ul className='navbar__list'>
              <li>
                <a href='#aboutMe'>ОБО МНЕ</a>
              </li>
              <li>
                <a href='#myWorks'>МОИ РАБОТЫ</a>
              </li>
              <li>
                <a href='#footer'>КОНТАКТЫ</a>
              </li>
            </ul>
          </div>

          <button type='button' className='navbar__contacts'>
            <img src={tel} alt='иконка телефона' className='navbar__tel_icon' />
            <a href='tel:+79920200277'>+7 (992) 020-02-77</a>
          </button>

          <div className='navbar__burger' onClick={() => setBurger(!burger)}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <BurgerActive active={burger} setActive={setBurger} />
        </nav>
      </div>
    </header>
  )
}

export default Header
