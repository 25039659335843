import React, { useState } from 'react'
import './myWorks.scss'

import CloseIcon from '@mui/icons-material/Close'

import house from './housePhoto'
import summerhouse from './summerhousePhoto'
import bathhouse from './bathhousePhoto'
import decorate from './decoratePhoto'

const type = [house, summerhouse, bathhouse, decorate]

function MyWorks() {
  const nameType = ['ДОМА', 'БАНИ', 'БЕСЕДКИ', 'ДЕКОР,ИНТЕРЬЕР']
  const [state, setState] = useState(0)

  const handleClick = (item, index) => {
    setState(index)
  }

  const [model, setModel] = useState(false)
  const [tempImg, setTempImg] = useState('')

  const getImg = (img) => {
    setTempImg(img)
    setModel(true)
  }

  return (
    <>
      <section className='myWorks' id='myWorks'>
        <div className='container'>
          <h2>МОИ РАБОТЫ</h2>
          <div className='myWorks__wrap'>
            <div className='myWorks__wrap_block'>
              <div className={model ? 'model open' : 'model'}>
                <img src={tempImg} alt={tempImg} />
                <CloseIcon onClick={() => setModel(false)} />
              </div>
              {type[state].map((item) => (
                <div className='myWorks__wrap_img' key={item.id} onClick={() => getImg(item.img)}>
                  <img src={item.img} alt={item.id} />
                </div>
              ))}
            </div>

            <div className='myWorks__buttons'>
              {nameType.map((item, index) => (
                <button
                  key={index}
                  className={`myWorks__button ${index === state ? 'active' : ''}`}
                  onClick={() => handleClick(item, index)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MyWorks
