import React from 'react'
import './features.scss'

function Features() {
  return (
    <>
      <section className='features'>
        <div className='container'>
          <div className='features__wrap'>
            <h2>Преимущества рубленого дома</h2>
            <p>
              В последнее время люди все чаще выбирают дерево для строительства домов. И хотя стоимость древесины только
              растёт, спрос на деревянные дома не падает. В чем же их преимущества?
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
