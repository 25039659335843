import React from 'react'
import './header.scss'
import insta from '../../footerIcon/insta.svg'
import mail from '../../footerIcon/email.svg'
import tel from '../../footerIcon/tel.svg'

function BurgerActive({ active, setActive }) {
  return (
    <>
      {/* <div className='navbar__burger_close'>
              <span></span>
              <span></span>
              <span></span>
            </div> */}
      <div className={active ? 'navbar__burger_menu active' : 'navbar__burger_menu'} onClick={() => setActive(!active)}>
        <ul className='burger__list'>
          <li className='burger__links'>
            <a href='#aboutMe'>ОБО МНЕ</a>
          </li>
          <li className='burger__links'>
            <a href='#myWorks'>МОИ РАБОТЫ</a>
          </li>
          <li className='burger__links'>
            <a href='#footer'>КОНТАКТЫ</a>
          </li>
        </ul>

        <ul className='burger__icons'>
          <li>
            <a href='https://www.instagram.com/korba_woodwork/' target='_blank' rel='noopener noreferrer'>
              <img src={insta} alt='/' />
            </a>
          </li>
          <li>
            <a href='mailto:istokles2017@yandex.ru' target='_blank' rel='noopener noreferrer'>
              <img src={mail} alt='/' />
            </a>
          </li>
          <li>
            <a href='tel:+79920200277'>
              <img src={tel} alt='/' />
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default BurgerActive
