import React from 'react'
import FeaturesList from './FeaturesList'

function FeaturesCard({ activeFeature }) {
  return (
    <div className='featuresCard'>
      <div className='featuresCard__img'>
        <img src={activeFeature.img} alt={activeFeature.title} />
      </div>

      <div className='featuresCard__text'>
        <FeaturesList img={activeFeature.icon} alt={activeFeature.title} title={activeFeature.title} />
        <p className='featuresCard__subTitle'>{activeFeature.text}</p>
      </div>
    </div>
  )
}

export default FeaturesCard
