import React from 'react'
import './aboutMe.scss'
import myPhoto from '../../pavel.jpg'

function AboutMe() {
  return (
    <>
      <section className='aboutMe' id='aboutMe'>
        <div className='container'>
          <div className='aboutMe__wrap'>
            <div className='aboutMe__wrap_text'>
              <h2 className='aboutMe__title'>ОБО МНЕ</h2>

              <h3 className='aboutMe__text margin'>Здравствуйте,</h3>

              <p className='aboutMe__text margin'>
                Меня зовут Павел Корба. Уже семь лет я строю деревянные дома. Первый дом начал строить для своей семьи и
                не планировал заниматься этим как бизнесом. Но ко мне стали обращаться соседи, затем знакомые соседей, и
                знакомые знакомых. Беседки, бани, небольшие загородные домики. Большое начинается с малого. Сейчас
                география моих работ – от Мурманска до Алматы. Домики превращаются в особняки, хотя я до сих пор берусь
                за интересные небольшие проекты.{' '}
              </p>

              <p className='aboutMe__text margin'>
                Давайте разберемся, подходят ли вам мои услуг и в чем их особенность:
              </p>

              <p className='aboutMe__text margin'>
                Я строю дома по индивидуальным проектам, а это значит, вы можете сами придумать свой дом, а
                профессиональный архитектор в нашей команде воплотит вашу мечту на бумаге. Кроме того, вы можете взять
                за основу проект из раздела «Мои работы».
              </p>

              <p className='aboutMe__text margin'>
                На ваш выбор несколько технологий и стилей деревянного домостроения: традиционная рубка «русская чашка»,
                канадская рубка, Post and Beam (каркасно-балочное строительство из бревна), Timber Frame
                (каркасно-балочное строительство из массивного бруса).
              </p>

              <p className='aboutMe__text margin  mob-none'>
                Я работаю только с качественным сертифицированным материалом, которое закупаю у лицензированных
                заготовителей.
              </p>

              <p className='aboutMe__text margin mob-none'>
                Использую щадящую технологию очистки бревна - гидроокорку, не повреждающую защитный слой и естественный
                рельеф дерева.
              </p>

              <p className='aboutMe__text margin mob-none'>
                Дома возводятся на собственной строительной площадке в г. Екатеринбурге, оборудованной всей необходимой
                техникой. Затем сруб разбирается и отправляется на место заказчика, где мы собираем его уже на
                фундаменте под крышу.
              </p>

              <p className='aboutMe__text mob-none '>
                Я не содержу штат менеджеров, не работаю с субподрядчиками. А значит, во-первых, в моих сметах нет
                переплат, во-вторых, на протяжении всех этапов работы вы будете общаться непосредственно с исполнителем
                – со мной.
              </p>
            </div>

            <div className='aboutMe__photo'>
              <img src={myPhoto} alt='Павел Корба' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutMe
