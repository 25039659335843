const decorate = [
  { id: 1, img: '/decorate/1.jpg' },
  { id: 2, img: '/decorate/2.jpg' },
  { id: 3, img: '/decorate/3.jpg' },
  { id: 4, img: '/decorate/4.jpg' },
  { id: 5, img: '/decorate/5.jpg' },
  { id: 6, img: '/decorate/6.jpg' },
  { id: 7, img: '/decorate/7.jpg' },
  { id: 8, img: '/decorate/8.jpg' },
  { id: 9, img: '/decorate/9.jpg' },
  { id: 10, img: '/decorate/10.jpg' },
  { id: 11, img: '/decorate/11.jpg' },
  { id: 12, img: '/decorate/12.jpg' },
]

export default decorate
